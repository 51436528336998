import React, { useState } from 'react';
import styled from "styled-components";
import { useUpdateEffect } from './Helpers.js';
import { categories } from './Constants.js';

import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { FaLocationArrow } from 'react-icons/fa';

import { Typeahead } from 'react-bootstrap-typeahead';

const CurrentLocationButton = styled(Button)`
  margin-left: -40px;
}
`

const LocationTypeahead = styled(Typeahead)`
  width: 95%;
}
`

function SearchFilterPanel({ locations, gpsButtonTapped, filtersChanged, selectedCategory }) {
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [filterCategory, setFilterCategory] = useState();

  useUpdateEffect(() => {
    filtersChanged(filterCategory, selectedLocation?.[0]?.geographicId);
  }, [selectedLocation, filterCategory]);

  return (
    <Nav fill className="mt-2">

      <Nav.Item className="mr-2">

        <InputGroup>
          <LocationTypeahead
            id="location-search-field"
            labelKey="geographicName"
            onChange={setSelectedLocation}
            options={locations}
            placeholder="Where are you going?"
            selected={selectedLocation}
          />
          <InputGroup.Append>
            <CurrentLocationButton onClick={gpsButtonTapped}
              variant="outline-primary"
              className="border-start-0 border-top-0 border-bottom-0 border">
              <FaLocationArrow />
            </CurrentLocationButton>
          </InputGroup.Append>
        </InputGroup>

      </Nav.Item>

      <Form>
        <Form.Group controlId="category-select">
          <Form.Control as="select" value={selectedCategory} custom onChange={e => setFilterCategory(e.target.value) }>
            <option value="0"> All Categories</option>
            {categories.map(category =>
              <option key={category.id} value={category.id}>{category.name}</option>
            )}
          </Form.Control>
        </Form.Group>
      </Form>

    </Nav>
  )
}

export default SearchFilterPanel;