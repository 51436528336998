import React from 'react';
import styled from "styled-components";

const StyledLabel = styled.span`
  font-size: 0.9em;
  font-family: 'mc-mark-narrow-medium', sans-serif;
`;

function FilterDetails({ count }) {
  return (
    <StyledLabel className="pl-2">{count} Results</StyledLabel>
  )
}

export default FilterDetails;