/*
* Welcome to your app's main JavaScript file!
*
* We recommend including the built version of this JavaScript file
* (and its CSS file) in your base layout (base.html.twig).
*/

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './components/Home';
import Languages from './components/Languages';
import NavBar from './components/Navbar';
import './styles/app.css';

ReactDOM.render(
  <Router>
    <NavBar/>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/languages" component={Languages} />
    </Switch>
  </Router>,
  document.getElementById('root')
);