import React from "react";
import styled from "styled-components";

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const StyledLink = styled(Nav.Link)`
  font-size: 1em;
  font-family: 'mc-mark-narrow-medium', sans-serif;
  color: white !important;
  
  &:hover {
    color: rgb(191, 78, 33) !important;
  };
`;

const StyledBar = styled(Navbar)`
  background-color: #141413;
`;

const NavImage = styled.img`
  max-height: 32px;
`;

const NavBar = () => {
  return (
    <StyledBar collapseOnSelect expand="lg" variant="dark">
      <Navbar.Brand href="/">
        <NavImage
          src="nav_logo.png"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse>
        <Nav className="mr-auto">
          <StyledLink href="languages">Languages</StyledLink>
        </Nav>
       
      </Navbar.Collapse>
    </StyledBar>
  );
};

export default NavBar;