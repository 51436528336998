import React, { Component } from 'react';
import { FaThumbtack, FaHandshake } from 'react-icons/fa';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import { PRICELESS_BASE__PRODUCT_URL } from './Constants';

class MapContainer extends Component {
  state = {
    activeMarker: {},
    selectedPlace: {},
    showingInfoWindow: false,
    currentLocation: {},
  };

  onMarkerClick = (props, marker) =>
    this.setState({
      activeMarker: marker,
      selectedPlace: props,
      showingInfoWindow: true
    });

  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      });
  };

  componentDidMount() {
    this.updateBounds();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.updateBounds();
    }

    if (this.props.currentLocation !== prevProps.currentLocation) {
      var bounds = this.map.map.getBounds();
      bounds.extend(this.props.currentLocation);
      this.map.map.fitBounds(bounds);
    }
  }

  render() {
    const selected = this.state.selectedPlace;
    const venue = selected.venue || "Available Online";

    return (
      <Map
        ref={(ref) => { this.map = ref; }}
        google={this.props.google}
        onClick={this.onMapClicked}
        style={{ height: '100vh', position: 'relative', width: '100%' }}>

        {this.props.data.map(place =>
          <Marker
            key={place.productId}
            url={PRICELESS_BASE__PRODUCT_URL + place.productId}
            name={place.displayName}
            venue={place.eventVenue}
            celeb={place.celebName}
            celebImageUrl={place.celebImageUrl}
            onClick={this.onMarkerClick}
            position={{ lat: parseFloat(place.latitude), lng: parseFloat(place.longitude) }}
          />
        )}

        {this.props.currentLocation &&
          <Marker
            position={this.props.currentLocation}
            icon={{
              url: "/location_dot.png",
              scaledSize: new window.google.maps.Size(25, 25)
            }}
          />
        }

        <InfoWindow
          maxWidth={340}
          marker={this.state.activeMarker}
          onClose={this.onInfoWindowClose}
          visible={this.state.showingInfoWindow}>
          <div>
            <div style={{ float: 'left' }}>
              <img style={{ borderRadius: 80 / 2, width: 80, height: 80 }} src={selected.celebImageUrl} alt={selected.celebName} />
            </div>

            <div style={{ marginLeft: 95 }}>
              <h6><a href={selected.url} style={{ color: 'black' }}>{selected.name}</a></h6>
              <div style={{ fontSize: 14 }}><FaHandshake color="#b44726" /> {selected.celeb}</div>
              <div style={{ fontSize: 14 }}><FaThumbtack color="#b44726" /> {venue}</div>
            </div>

          </div>

        </InfoWindow>
      </Map>
    );
  }

  updateBounds() {
    this.map.map.fitBounds(this.calculateBounds(this.props.data));

    // Special case: zoom out a bit
    if (this.props.data.length === 1) {
      this.map.map.setZoom(13);
    }
  }

  calculateBounds(places) {
    const bounds = new window.google.maps.LatLngBounds();

    if (places.length === 0) {
      bounds.extend(new window.google.maps.LatLng(85, -180));
      bounds.extend(new window.google.maps.LatLng(-85, 180));
      return bounds;
    }

    places.map(place => {
        const lat = parseFloat(place.latitude);
        const lng = parseFloat(place.longitude);
        const latLng = new window.google.maps.LatLng(lat, lng);
        bounds.extend(latLng);
        return latLng;
    });

    return bounds;
  }

}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBU6aLwjpih8hzanFw7ZA_yO3PAROyP0H4'
})(MapContainer);

