import React, { useState, useEffect } from 'react';

import axios from 'axios';
import SearchFilterPanel from './SearchFilterPanel';
import FilterDetails from './FilterDetails';
import Sidebar from './ResultsSidebar';

import GoogleApiWrapper from './MapContainer';

import Spinner from "react-spinners/ClipLoader";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState();
  const [selectedCategory, setSelectedCategory] = useState(0);

  useEffect(() => {
    const fetchLocations = axios.get(`api/locations`)
    Promise.all([fetchProducts(), fetchLocations]).then(function(values) {
      setProducts(values[0].data.data);
      setLocations(values[1].data.data);
      setIsLoading(false);
    });
  }, [])

  function fetchProducts(category = "", geographicId = "") {
    const preferredLang = localStorage.getItem('kPreferredLanguage') || 1;

    return axios.get(`api/products`, {
      params: {
        language: preferredLang,
        category: category,
        geographicId: geographicId
      }
    })
  }

  // ACTION CALLBACKS:

  function locationButtonTapped(evt) {
    navigator?.geolocation?.getCurrentPosition(pos => {
      const coords = pos.coords;
      setCurrentLocation({
        lat: coords.latitude,
        lng: coords.longitude
      });
    });
  }

  function filtersChanged(category, location) {
    setSelectedCategory(category);
    setIsLoading(true);

    fetchProducts(category, location).then(function (response) {
      setProducts(response.data.data);
      setIsLoading(false);
    })
  }

  const productsWithCoordinates = products.filter(place => place.latitude);
    
  return (
  <Container fluid>
    <Row>
      <Col xs={12} md={5} lg={4}>

        <SearchFilterPanel 
          locations={locations}
          gpsButtonTapped={locationButtonTapped}
          filtersChanged={filtersChanged}
          selectedCategory={selectedCategory}/>

        <FilterDetails count={products.length} />

        {isLoading ? (
          <div className={'row py-5 text-center justify-content-center'}>
            <Spinner size={150} />
          </div>
        ) :
          <Sidebar products={products}/> 
        }
      </Col>
      <Col xs={12} md={7} lg={8} className="px-0">
        <div className="sticky-top">
          <GoogleApiWrapper data={productsWithCoordinates} currentLocation={currentLocation} />
        </div>
      </Col>
    </Row>
  </Container>
  )
}
    
export default Home;
    