import React from 'react';
import styled from "styled-components";
import { PRICELESS_BASE__PRODUCT_URL } from './Constants';
import { FaThumbtack, FaGlobe } from 'react-icons/fa';

import ListGroup from 'react-bootstrap/ListGroup';
import Image from 'react-bootstrap/Image';
import Alert from 'react-bootstrap/Alert';

const BoldLabel = styled.small`
  font-size: 1em;
  font-family: 'mc-mark-narrow-medium', sans-serif;
`

const Row = styled(ListGroup.Item)`
  &.active {
    background-color: rgb(191, 78, 33);
    border-color: #000;
  };
`;

function Sidebar({ products }) {

  return (
    <ListGroup className="my-2">

      {!products.length &&
        <Alert variant="warning">
          No matching offers for the selected filters were found.
        </Alert>
      }

      {products.map(product =>
        
        <Row key={product.productId} action href={PRICELESS_BASE__PRODUCT_URL + product.productId}>

          <Image src={product.imageUrl} fluid />

          <div className="d-flex w-100 my-2 justify-content-between">
            <BoldLabel>{product.celebName}</BoldLabel>

            {product.price !== "0.00" &&
              <BoldLabel>{product.currencyCharacter}{product.price}</BoldLabel>
            }
          </div>

          <div className="d-flex w-100 justify-content-between">
            <h5 className="mb-1">{product.productName}</h5>
          </div>
          
          {product.eventCity ? (
            <p className="mb-1">
              <FaThumbtack color="#b44726" /> {[product.eventVenue, product.eventCity].filter(Boolean).join(", ")}
            </p>
          ) :
            <p className="mb-1"><FaGlobe color="#b44726" /> Available Online</p>
          }

        </Row>
      )}
    </ListGroup>
  )
}

export default Sidebar;