import React, { useState, useEffect } from 'react';
import { useStateWithLocalStorage } from './Helpers.js';
import axios from 'axios';
import styled from "styled-components";

import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from "react-spinners/ClipLoader";


const Row = styled(ListGroup.Item)`
  &.active {
    background-color: rgb(191, 78, 33);
    border-color: #000;
  };
`;

function LanguagesList() {
  const [isLoading, setIsLoading] = useState(true);
  const [languages, setLanguages] = useState([]);

  const [preferredLangId, setPreferredLangId] = useStateWithLocalStorage(
    'kPreferredLanguage'
  );

  useEffect(() => {
    document.title = "Languages"

    axios.get(`api/languages`)
      .then(resp => {
        setLanguages(resp.data.data);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        setIsLoading(false);
      });
  }, [])

  return (
    <Container>
      <h4 className="py-3">Select a default language for API requests:</h4>  

      {isLoading ? (
        <div className={'row text-center justify-content-center'}>
          <Spinner size={150} />
        </div>
      ) : (

        <ListGroup className="pb-3">
          {languages.map(language =>
            <Row action key={language.languageId} 
              active={language.languageId === preferredLangId} 
              onClick={() => setPreferredLangId(language.languageId)}>
                {language.languageName}
            </Row>
          )}
        </ListGroup>
      )}
    </Container>
  )
}

export default LanguagesList;
